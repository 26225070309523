import React, { useEffect, useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import { useLazyQuery } from '@apollo/client'
import { CircleLoader } from 'react-spinners'
import Centered from '../layout/Centered'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../../energy/queries/metrics'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as Moment from 'moment'
import mTZ from 'moment-timezone'
import { transformChartData } from '../../utils/transformChartData'
import { getTimeframe } from '../../utils/getTimeframe'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

declare global {
  interface Window {
    moment: any
  }
}
window.moment = Moment
mTZ()

const StyledCard = styled.div`
  box-shadow: 0 0 32px rgba(0, 0, 100, 0.08);
  padding: 24px;
  width: 100%;
`

const Title = styled.div`
  font-family:
    Red Hat Display,
    sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
`

type Device = {
  name: string
  deviceId: string
}

interface Props {
  title: string
  devices: Device[]
  measurement: string
  isExport?: boolean
}

export default ({ devices, title, measurement, isExport }: Props) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [fetchData, { data, loading }] = useLazyQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    fetchPolicy: 'no-cache'
  })

  const deviceIds = useMemo(() => {
    return devices?.map(device => device.deviceId) ?? []
  }, [devices])

  const { start, end, window, tickValues } = useMemo(() => {
    return getTimeframe(selectedTimeframe)
  }, [selectedTimeframe])

  useEffect(() => {
    if (deviceIds && deviceIds.length > 0 && selectedTimeframe) {
      void fetchData({
        variables: {
          input: {
            deviceId: deviceIds,
            start,
            end,
            measurement,
            window
          }
        }
      })
    }
  }, [start, end, window, devices])

  const energyData = useMemo(() => {
    if (isExport) {
      return (
        data?.getWindowedMeasurements
          ?.filter((measurement: { _value: any }) => Boolean(measurement._value))
          //@ts-ignore
          .map(measurement => {
            const newValue = -Math.abs(measurement._value)
            return {
              ...measurement,
              _value: newValue
            }
          }) ?? []
      )
    } else {
      return data?.getWindowedMeasurements ?? []
    }
  }, [data?.getWindowedMeasurements])

  const chartData = useMemo(() => {
    return transformChartData({ data: energyData, devices, type: 'column' })
  }, [energyData, devices])

  const chartOptions: Highcharts.Options = {
    title: undefined,
    chart: {
      type: 'column'
    },
    xAxis: {
      type: 'datetime',
      tickInterval: tickValues
    },
    yAxis: {
      title: {
        text: 'kWh'
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        groupPadding: 0
      }
    },
    credits: {
      enabled: false
    },
    time: {
      timezone: 'Europe/Amsterdam'
    },
    //@ts-ignore
    series: chartData
  }

  return (
    <StyledCard className="bg-white shadow xs:mx-auto w-full">
      <Title>{title}</Title>

      {loading && (
        <Centered>
          <CircleLoader color="#0D70EE" />
        </Centered>
      )}

      {!loading && chartData && chartData.length > 0 && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}

      {!loading && chartData.length === 0 && <Centered>Er is geen data beschikbaar</Centered>}
    </StyledCard>
  )
}
