import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LoginScreen from '../auth/screens/LoginScreen'
import RequireAuth from './ProtectedRoute'
import DashboardScreen from '../screens/Dashboard/DashboardScreen'
import LogoutScreen from '../auth/screens/LogoutScreen'
import BuildingSectionScreen from '../screens/Building/BuildingSectionScreen'
import GridRootScreen from '../energy/grid/GridRootScreen'
import GridDistributionBoxScreen from '../energy/grid/GridDistributionBoxScreen'
import EvChargingScreen from '../screens/EVCharging/EvChargingScreen'
import EnergyPerCategoryScreen from '../energy/categories/EnergyPerCategoryScreen'
import CategoriesRootScreen from '../energy/categories/CategoriesRootScreen'
import BuildingScreen from '../screens/Building/BuildingScreen'
import { LoginCallbackScreen } from '../auth/screens/LoginCallbackScreen'
import { LogoutCallbackScreen } from '../auth/screens/LogoutCallbackScreen'
import { AccountNotReadyErrorScreen } from '../screens/errors/AccountNotReadyErrorScreen'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/test" element={<>Test</>} />
        <Route path="/auth/callback" element={<LoginCallbackScreen />} />
        <Route path="/auth/logout" element={<LogoutCallbackScreen />} />

        <Route
          path="/logout"
          element={
            <RequireAuth>
              <LogoutScreen />
            </RequireAuth>
          }
        />

        <Route
          path="/account-not-ready"
          element={
            <RequireAuth>
              <AccountNotReadyErrorScreen />
            </RequireAuth>
          }
        />

        <Route
          path="/"
          element={
            <RequireAuth>
              <DashboardScreen />
            </RequireAuth>
          }
        />

        <Route
          path="/building"
          element={
            <RequireAuth>
              <BuildingScreen />
            </RequireAuth>
          }
        />

        <Route
          path="/grid"
          element={
            <RequireAuth>
              <GridRootScreen />
            </RequireAuth>
          }
        />

        <Route
          path="/grid/:boxId"
          element={
            <RequireAuth>
              <GridDistributionBoxScreen />
            </RequireAuth>
          }
        />

        <Route
          path="/grid/:boxId/:circuitId"
          element={
            <RequireAuth>
              <GridDistributionBoxScreen />
            </RequireAuth>
          }
        />

        <Route
          path={'/section/:sectionId'}
          element={
            <RequireAuth>
              <BuildingSectionScreen />
            </RequireAuth>
          }
        />

        <Route
          path={'/ev-charging'}
          element={
            <RequireAuth>
              <EvChargingScreen />
            </RequireAuth>
          }
        />

        <Route
          path={'/categories'}
          element={
            <RequireAuth>
              <CategoriesRootScreen />
            </RequireAuth>
          }
        />

        <Route
          path={'/categories/:category'}
          element={
            <RequireAuth>
              <EnergyPerCategoryScreen />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
