import React, { useEffect, useMemo } from 'react'
import AppLayout from '../../layouts/AppLayout'
import { useParams } from 'react-router-dom'
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client'
import { CircleLoader } from 'react-spinners'
import { activeTopBarMenu, topBarMenuItems } from '../../components/TopBar/TopBar'
import GridDistributionBoxOverview from './GridDistributionBoxOverview'
import GridDistributionBoxEnergie from './GridDistributionBoxEnergie'
import GridDistributionBoxPower from './GridDistributionBoxPower'
import GridDistributionBoxCurrent from './GridDistributionBoxCurrent'
import GridDistributionBoxPowerQuality from './GridDistributionBoxPowerQuality'
import { GET_GRID_DISTRIBUTION_BOX_QUERY } from '../queries/grid'

export default () => {
  const { boxId, circuitId } = useParams()
  const [fetchBox, { data, loading }] = useLazyQuery(GET_GRID_DISTRIBUTION_BOX_QUERY, {
    fetchPolicy: 'no-cache'
  })
  const activeMenu = useReactiveVar(activeTopBarMenu)
  //@todo: 404 page
  if (!boxId) return null

  useEffect(() => {
    fetchBox({
      variables: {
        distributionBoxId: boxId
      }
    })
  }, [boxId])

  useEffect(() => {
    topBarMenuItems([
      // {
      //     id: "overzicht",
      //     title: "Overzicht",
      //     to: ""
      // },
      {
        id: 'energie',
        title: 'Energie',
        to: ''
      },
      {
        id: 'vermogen',
        title: 'Vermogen',
        to: ''
      }
      // {
      //     id: "belasting",
      //     title: "Belasting",
      //     to: ""
      // },
      // {
      //     id: "power-quality",
      //     title: "Power quality",
      //     to: ""
      // }
    ])

    return () => {
      topBarMenuItems([])
    }
  })

  const distributionBox = useMemo(() => {
    return data?.getGridDistributionBox ?? {}
  }, [data?.getGridDistributionBox])

  return (
    <AppLayout title={distributionBox.name}>
      {(loading || !data) && (
        <div className={'h-full w-full flex justify-center items-center'}>
          <CircleLoader color="#0D70EE" />
        </div>
      )}
      {!loading && distributionBox && activeMenu === 'overzicht' && <GridDistributionBoxOverview distributionBox={distributionBox} />}

      {!loading && distributionBox && activeMenu === 'energie' && <GridDistributionBoxEnergie distributionBox={distributionBox} />}

      {!loading && distributionBox && activeMenu === 'vermogen' && <GridDistributionBoxPower distributionBox={distributionBox} />}

      {!loading && distributionBox && activeMenu === 'belasting' && <GridDistributionBoxCurrent distributionBox={distributionBox} />}

      {!loading && distributionBox && activeMenu === 'power-quality' && <GridDistributionBoxPowerQuality distributionBox={distributionBox} />}
    </AppLayout>
  )
}
