import React, { useMemo } from 'react'
import Source from './SourceComponent'
import { Circle, CircleInner } from './CircleComponent'
import styled from '@emotion/styled'
import SolarIcon from '../../../icons/SolarIcon'
import { Stat, StatIcon, StatMetric, StatsContainer } from './StatsComponents'
import { useQuery, useReactiveVar } from '@apollo/client'
import { getTimeframe } from '../../../utils/getTimeframe'
import { FETCH_DELTA_FOR_PERIOD_QUERY } from '../../../energy/queries/metrics'
import Big from 'big.js'
import RightArrowIcon from '../../../icons/RightArrowIcon'
import { Name } from './NameComponent'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../../store'

const SolarCircleInner = styled(CircleInner)`
  border-color: rgba(245, 191, 51, 1);
  gap: 8px;
`

interface PowerOverviewSolarComponentProps {
  solar: any
}

const PowerOverviewSolarComponent = ({ solar }: PowerOverviewSolarComponentProps) => {
  const selectedTimeframe = useRecoilValue(timeframe)

  const devices = useMemo(() => {
    // @ts-ignore
    return solar?.map(device => device.deviceId) ?? []
  }, [solar])

  const { start, end, window, tickValues } = useMemo(() => {
    return getTimeframe(selectedTimeframe)
  }, [selectedTimeframe])

  const { data: exportData } = useQuery(FETCH_DELTA_FOR_PERIOD_QUERY, {
    variables: {
      input: {
        devices: devices,
        end,
        start,
        measurement: 'energyExported'
      }
    },
    pollInterval: 5000,
    skip: !Boolean(solar?.length > 0)
  })

  const totalExport: Big = useMemo(() => {
    // @ts-ignore
    return (
      exportData?.getDeltaForPeriod?.reduce((acc, cv) => {
        return acc.plus(Big(cv.delta))
      }, Big(0)) ?? Big(0)
    )
  }, [exportData?.getDeltaForPeriod])

  return (
    <Source>
      <Circle>
        <SolarCircleInner>
          <Name>Opwek</Name>
          <SolarIcon fill="#9196BB" />
          <StatsContainer>
            <Stat>
              <StatIcon>
                <RightArrowIcon />
              </StatIcon>
              <StatMetric>{totalExport.round(1).toNumber()} kWh</StatMetric>
            </Stat>
          </StatsContainer>
        </SolarCircleInner>
      </Circle>
    </Source>
  )
}

export default PowerOverviewSolarComponent
