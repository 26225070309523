import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import TimeframeSelectionMenu from '../../components/TimeframeSelectionMenu/TimeframeSelectionMenu'
import CircuitsSubTitle from '../../components/Typography/CircuitsSubTitle'

const Devices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  justify-items: flex-start;
  margin-top: 16px;
`

interface Props {
  totals?: ReactElement[]
  charts?: ReactElement[]
  deviceCards?: ReactElement[]
  circuitsTitle: string
}

const EnergyContainer = ({ charts = [], deviceCards = [], circuitsTitle, totals }: Props) => {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex flex-row justify-end">
        <TimeframeSelectionMenu />
      </div>

      {totals && totals.length > 0 && <div className="w-full flex flex-row mt-12 justify-center items-center gap-x-6">{totals}</div>}

      {charts.map(chart => (
        <div className="w-full mt-20">{chart}</div>
      ))}

      {deviceCards && deviceCards.length > 0 && <CircuitsSubTitle>{circuitsTitle}</CircuitsSubTitle>}

      {deviceCards && deviceCards.length > 0 && <Devices>{deviceCards}</Devices>}
    </div>
  )
}

export default EnergyContainer
