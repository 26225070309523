import { useQuery, useReactiveVar } from '@apollo/client'
import { CircleLoader } from 'react-spinners'
import EnergyDeviceCard, { Measurement, MeasurementContainer, MeasurementFraction, MeasurementLabel } from '../base/EnergyDeviceCard'
import Big from 'big.js'
import { useMemo } from 'react'
import { GetMinMaxForPeriodQuery } from '../../energy/queries/metrics'
import { EnergyDevice } from '../../energy/types/devices'
import { getTimeframe } from '../../utils/getTimeframe'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

interface Props {
  device: EnergyDevice
}

export default ({ device }: Props) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const { start, end, window } = getTimeframe(selectedTimeframe)
  const { data, loading } = useQuery(GetMinMaxForPeriodQuery, {
    variables: {
      input: {
        deviceId: device.deviceId,
        start,
        end,
        measurement: 'activePower'
      }
    },
    skip: !device?.deviceId
  })

  const { minKwIntegral, minKwFraction, maxKwFraction, maxKwIntegral } = useMemo(() => {
    const min = Big(data?.getMinMaxForPeriod?.min?.value ?? 0)
    const minKw = min.lt(1000) ? min.round(2) : min.div(1000).round(2)
    const minKwIntegral = minKw.round(0, Big.roundDown)
    const minKwFraction = minKw.minus(minKwIntegral).round(2).toString().split('.')[1]

    const max = Big(data?.getMinMaxForPeriod?.max?.value ?? 0)
    const maxKw = max.lt(1000) ? max.round(2) : max.div(1000).round(2)
    const maxKwIntegral = maxKw.round(0, Big.roundDown)
    const maxKwFraction = maxKw.minus(maxKwIntegral).round(2).toString().split('.')[1]

    return {
      minKwIntegral: minKwIntegral.toString(),
      minKwFraction,
      maxKwIntegral: maxKwIntegral.toString(),
      maxKwFraction
    }
  }, [data?.getMinMaxForPeriod])

  if (!device?.deviceId) return null
  if (!data?.getMinMaxForPeriod && !loading) return null

  return (
    <EnergyDeviceCard name={device.name}>
      {loading && <CircleLoader color="#0D70EE" loading={loading} />}
      {!loading && (
        <>
          <MeasurementContainer>
            <MeasurementLabel>Min.</MeasurementLabel>
            <Measurement>
              {minKwIntegral}
              <MeasurementFraction> {minKwFraction && `,${minKwFraction}`} kW</MeasurementFraction>
            </Measurement>
          </MeasurementContainer>

          <MeasurementContainer>
            <MeasurementLabel>Max.</MeasurementLabel>
            <Measurement>
              {maxKwIntegral}
              <MeasurementFraction> {maxKwFraction && `,${maxKwFraction}`} kW</MeasurementFraction>
            </Measurement>
          </MeasurementContainer>
        </>
      )}
    </EnergyDeviceCard>
  )
}
