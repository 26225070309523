import { useEffect } from 'react'
import { useResetRecoilState } from 'recoil'
import { user } from '../../store'
import useAppContext from '../../hooks/useAppContext'
import { LoadingScreen } from '../../screens/LoadingScreen'

const LogoutScreen = () => {
  const resetUser = useResetRecoilState(user)
  const { authClient } = useAppContext()

  useEffect(() => {
    resetUser()
    void authClient.signout()
  }, [])

  return <LoadingScreen />
}

export default LogoutScreen
