//@ts-nocheck
import React, { Suspense, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import AppLayout from '../../layouts/AppLayout'
import { GetDeviceCategories } from '../../devices/queries/categories'
import useBuildingContext from '../../building/hooks/useBuildingContext'
import { EnergyCategoryColumn } from './EnergyCategoryColumn'
import { EnergyTableColumn } from '../../components/Energy/EnergyTable/EnergyTableColumn'
import { EnergyTypes } from '../../components/Energy/EnergyTable/EnergyTableResult'
import { EnergyTable } from '../../components/Energy/EnergyTable/EnergyTable'
import TimeframeSelectionMenu from '../../components/TimeframeSelectionMenu/TimeframeSelectionMenu'
import styled from '@emotion/styled'
import { GridSummaryGraphic } from '../components/GridSummaryGraphic'
import { EnergyPerCategoryGridTotals } from './EnergyPerCategoryGridTotals'
import { useRecoilValue } from 'recoil'
import { building } from '../../store'

const Container = styled('div')`
  width: 100%;
  height: fit-content;
`

const TimeFrameContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const DetailContainer = styled('div')`
  //min-width: fit-content;
  width: 100%;
  height: fit-content;
  margin: 50px auto 0;
  display: flex;
  align-items: center;
  max-width: 1400px;
`

const getCategoryData = (value: any) => {
  const result = {}

  if (value.imported.total >= 0) {
    result.imported = {
      type: EnergyTypes.Usage,
      values: value.imported.values,
      max: value.imported.total
    }
  }

  if (value.exported.total >= 0) {
    result.exported = {
      type: EnergyTypes.Solar,
      values: value.exported.values,
      max: value.exported.total
    }
  }

  return result
}

export default () => {
  const { activeBuildingId } = useRecoilValue(building)
  const [categoryData, setCategoryData] = useState(new Map())
  const [gridData, setGridData] = useState(new Map())

  const { data } = useQuery(GetDeviceCategories, {
    variables: {
      buildingId: activeBuildingId
    },
    skip: !Boolean(activeBuildingId)
  })

  const categories = useMemo(() => {
    return data?.getAvailableCategories?.toSorted() ?? []
  }, [data])

  const results = useMemo(() => {
    const result: EnergyTableColumn[] = []

    categoryData.forEach((value, key) => {
      result.push({
        title: key,
        link: `/categories/${key}`,
        ...getCategoryData(value)
      })
    })

    if (gridData.has('consumption')) {
      result.splice(0, 0, {
        title: 'Verbruik',
        imported: {
          type: EnergyTypes.Usage,
          values: gridData.get('consumption').values,
          max: gridData.get('consumption').total
        }
      })
    }

    if (gridData.has('solar')) {
      const position = gridData.has('consumption') ? 1 : 0
      result.splice(position, 0, {
        title: 'Opwek',
        imported: {
          type: EnergyTypes.Solar,
          values: gridData.get('solar').values,
          max: gridData.get('solar').total
        }
      })
    }

    return result
  }, [gridData, categoryData])

  const categoryOnComplete = ({ category, imported, exported }) => {
    setCategoryData(new Map(categoryData.set(category, { imported, exported })))
  }

  const gridTotalsOnComplete = ({ solar, consumption }) => {
    const grid = new Map()
    if (consumption) grid.set('consumption', consumption)
    if (solar) grid.set('solar', solar)
    setGridData(grid)
  }

  return (
    <AppLayout title={'Categoriën'}>
      <EnergyPerCategoryGridTotals gridTotalsOnComplete={gridTotalsOnComplete} />
      {categories.map(category => (
        <EnergyCategoryColumn category={category} onComplete={categoryOnComplete} key={`energy-category-column-${category}`} />
      ))}

      <Container>
        <TimeFrameContainer>
          <TimeframeSelectionMenu />
        </TimeFrameContainer>

        <GridSummaryGraphic />

        <DetailContainer>
          <Suspense>
            <EnergyTable results={results} />
          </Suspense>
        </DetailContainer>
      </Container>
    </AppLayout>
  )
}
