import styled from '@emotion/styled'
import { EnergyTableResult, EnergyTypes } from './EnergyTableResult'
import React from 'react'
import { EnergyTableColumnData } from './EnergyTableColumn'
import { Link, useNavigate } from 'react-router-dom'

const Column = styled('div')<{ isClickable: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 260px;
  padding: 24px 16px 16px 16px;
  border-top: 5px solid #ffffff;

  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};

  &:hover {
    box-shadow: ${({ isClickable }) => (isClickable ? '0px 0px 25px 1px rgba(191,194,214,0.68)' : undefined)};
    border-top: 5px solid ${({ theme, isClickable }) => (isClickable ? theme.colors.blueEnergetic : '#FFFFFF')};
  }
`

const Header = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 22px;
  padding: 8px 0;
`

const Title = styled('div')`
  font-family: 'Red Hat Display';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
`

const Results = styled('div')`
  width: 100%;
`

const Total = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
`

const Value = styled('span')`
  font-family: 'Red Hat Display';
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  color: ${({ theme }) => theme.colors.graphTextColor};
`

const Unit = styled('span')`
  color: ${({ theme }) => theme.colors.graphTextColor};
  font-family: 'Red Hat Display';
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
`

interface EnergyTableSingleCategoryColumnProps {
  title: string
  link?: string
  max: number
  values: EnergyTableColumnData[]
  type: EnergyTypes
}

export const EnergyTableSingleCategoryColumn = ({ title, link, max, values, type }: EnergyTableSingleCategoryColumnProps) => {
  const navigate = useNavigate()
  const navigateToLink = () => {
    if (link) navigate(link)
  }

  return (
    <Column isClickable={Boolean(link)} onClick={navigateToLink}>
      <Header>
        <Title>{title}</Title>
        <Total>
          <Value>{max}</Value>
          <Unit>kWh</Unit>
        </Total>
      </Header>
      <Results>
        {values.map(slot => (
          <EnergyTableResult type={type} value={slot.value} max={max} key={`result-${title}-${type}-${slot.value}-${slot.timestamp}`} />
        ))}
      </Results>
    </Column>
  )
}
