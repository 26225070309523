import { Circle, CircleInner } from './CircleComponent'
import styled from '@emotion/styled'
import RightArrowIcon from '../../../icons/RightArrowIcon'
import LeftArrowIcon from '../../../icons/LeftArrowIcon'
import React, { useMemo } from 'react'
import BatteryIcon from '../../../icons/BatteryIcon'
import { Stat, StatIcon, StatMetric, StatsContainer } from './StatsComponents'
import Source from './SourceComponent'
import { useQuery } from '@apollo/client'
import { getTimeframe } from '../../../utils/getTimeframe'
import { FETCH_DELTA_FOR_PERIOD_QUERY } from '../../../energy/queries/metrics'
import Big from 'big.js'
import { Name } from './NameComponent'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../../store'

const StorageCircleInner = styled(CircleInner)`
  border-color: rgba(248, 58, 157, 1);
  gap: 8px;
`

interface PowerOverviewStorageComponentProps {
  storage: any
}

const PowerOverviewStorageComponent = ({ storage }: PowerOverviewStorageComponentProps) => {
  const selectedTimeframe = useRecoilValue(timeframe)

  const devices = useMemo(() => {
    // @ts-ignore
    return storage?.map(device => device.deviceId) ?? []
  }, [storage])

  const { start, end, window, tickValues } = useMemo(() => {
    return getTimeframe(selectedTimeframe)
  }, [selectedTimeframe])

  const { data: importData } = useQuery(FETCH_DELTA_FOR_PERIOD_QUERY, {
    variables: {
      input: {
        devices: devices,
        end,
        start,
        measurement: 'energyImported'
      }
    },
    pollInterval: 5000,
    skip: !Boolean(storage?.length > 0)
  })

  const { data: exportData } = useQuery(FETCH_DELTA_FOR_PERIOD_QUERY, {
    variables: {
      input: {
        devices: devices,
        end,
        start,
        measurement: 'energyExported'
      }
    },
    pollInterval: 5000,
    skip: !Boolean(storage?.length > 0)
  })

  const totalImport: Big = useMemo(() => {
    // @ts-ignore
    return (
      importData?.getDeltaForPeriod?.reduce((acc, cv) => {
        return acc.plus(Big(cv.delta))
      }, Big(0)) ?? Big(0)
    )
  }, [importData?.getDeltaForPeriod])

  const totalExport: Big = useMemo(() => {
    // @ts-ignore
    return (
      exportData?.getDeltaForPeriod?.reduce((acc, cv) => {
        return acc.plus(Big(cv.delta))
      }, Big(0)) ?? Big(0)
    )
  }, [exportData?.getDeltaForPeriod])

  return (
    <Source>
      <Circle>
        <StorageCircleInner>
          <Name>Opslag</Name>
          <BatteryIcon fill="#9196BB" />
          <StatsContainer>
            <Stat>
              <StatIcon>
                <RightArrowIcon />
              </StatIcon>
              <StatMetric>{totalImport.round(1).toNumber()} kWh</StatMetric>
            </Stat>
            <Stat>
              <StatIcon>
                <LeftArrowIcon />
              </StatIcon>
              <StatMetric>{totalExport.round(1).toNumber()} kWh</StatMetric>
            </Stat>
          </StatsContainer>
        </StorageCircleInner>
      </Circle>
    </Source>
  )
}

export default PowerOverviewStorageComponent
