import { useQuery } from '@apollo/client'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../../../energy/queries/metrics'
import { getTimeframe, TimeframeVariant } from '../../../utils/getTimeframe'
import { useMemo } from 'react'
import Big from 'big.js'
import { format, isSameYear, parseISO } from 'date-fns'
import { EnergyTable } from '../../../components/Energy/EnergyTable/EnergyTable'
import { EnergyTableColumn } from '../../../components/Energy/EnergyTable/EnergyTableColumn'
import { EnergyTypes } from '../../../components/Energy/EnergyTable/EnergyTableResult'
import { timeframe, Timeframe, TimeframeWindow } from '../../../store'
import { useRecoilValue } from 'recoil'

interface PowerOverviewEnergyTableProps {
  gridDeviceIds?: string[]
  solarDeviceIds?: string[]
  consumptionDeviceIds?: string[]
}

const dateToTimestamp = (date: Date, timeFrame: Timeframe) => {
  const sameYear = isSameYear(date, new Date())

  switch (timeFrame.window) {
    case TimeframeWindow.H24:
      return format(date, 'HH:mm')
    case TimeframeWindow.WEEK:
    case TimeframeWindow.MONTH:
      return format(date, 'EEEEEE dd')
    case TimeframeWindow.YEAR:
      return format(date, 'MMMM yy')
    case TimeframeWindow.CUSTOM:
      return sameYear ? format(date, 'dd-MM-yy HH:mm') : format(date, 'dd MM HH:mm')
  }
}

export const energyTableGetTotal = (measurements: any) => {
  // @ts-ignore
  return measurements.reduce((sum, currentValue) => {
    const value = new Big(currentValue._value ?? 0)
    return sum.plus(value)
  }, new Big(0))
}

type Values = {
  [time: string]: Big
}

export const energyTableGetValues = (measurements: any, selectedTimeframe: Timeframe) => {
  let values: Values = {}

  //@ts-ignore
  measurements.forEach(measurement => {
    const date = parseISO(measurement._time)
    const timestamp = dateToTimestamp(date, selectedTimeframe)
    if (timestamp && values[timestamp]) {
      values[timestamp] = values[timestamp].plus(new Big(measurement._value ?? 0))
    } else if (timestamp) {
      values[timestamp] = new Big(measurement._value ?? 0)
    }
  })

  return (
    Object.entries(values).map(([key, value]) => {
      return {
        timestamp: key,
        value: value.round(0, Big.roundHalfUp).toNumber()
      }
    }) ?? []
  )
}

export const PowerOverviewEnergyTable = ({ gridDeviceIds, solarDeviceIds, consumptionDeviceIds }: PowerOverviewEnergyTableProps) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const { start, end, window } = getTimeframe({ ...selectedTimeframe, variant: TimeframeVariant.CONDENSED })

  const { data: energyImported } = useQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    variables: {
      input: {
        deviceId: gridDeviceIds,
        start,
        end,
        measurement: 'energyImported',
        window
      }
    },
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
    skip: !Boolean(gridDeviceIds && gridDeviceIds.length > 0)
  })

  const { data: energyExported } = useQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    variables: {
      input: {
        deviceId: gridDeviceIds,
        start,
        end,
        measurement: 'energyExported',
        window
      }
    },
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
    skip: !Boolean(gridDeviceIds && gridDeviceIds.length > 0)
  })

  const { data: solarExport } = useQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    variables: {
      input: {
        deviceId: solarDeviceIds,
        start,
        end,
        measurement: 'energyExported',
        window
      }
    },
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
    skip: !Boolean(solarDeviceIds && solarDeviceIds.length > 0)
  })

  const { data: consumptionData } = useQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    variables: {
      input: {
        deviceId: consumptionDeviceIds,
        start,
        end,
        measurement: 'energyImported',
        window
      }
    },
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
    skip: !Boolean(consumptionDeviceIds && consumptionDeviceIds.length > 0)
  })

  const gridImported = useMemo(() => {
    if (!energyImported?.getWindowedMeasurements || energyImported.getWindowedMeasurements.length < 1)
      return {
        total: 0,
        values: []
      }

    const total = energyTableGetTotal(energyImported.getWindowedMeasurements)
    const values = energyTableGetValues(energyImported.getWindowedMeasurements, selectedTimeframe)

    return {
      total: total.round(0, Big.roundHalfUp).toNumber(),
      values
    }
  }, [energyImported?.getWindowedMeasurements, selectedTimeframe])

  const gridExported = useMemo(() => {
    if (!energyExported?.getWindowedMeasurements || energyExported.getWindowedMeasurements.length < 1)
      return {
        total: 0,
        values: []
      }

    const total = energyTableGetTotal(energyExported.getWindowedMeasurements)
    const values = energyTableGetValues(energyExported.getWindowedMeasurements, selectedTimeframe)

    return {
      total: total.round(0, Big.roundHalfUp).toNumber(),
      values
    }
  }, [energyExported?.getWindowedMeasurements, selectedTimeframe])

  const solarExported = useMemo(() => {
    if (!solarExport?.getWindowedMeasurements || solarExport.getWindowedMeasurements.length < 1)
      return {
        total: 0,
        values: []
      }

    const total = energyTableGetTotal(solarExport.getWindowedMeasurements)
    const values = energyTableGetValues(solarExport.getWindowedMeasurements, selectedTimeframe)

    return {
      total: total.round(0, Big.roundHalfUp).toNumber(),
      values
    }
  }, [solarExport?.getWindowedMeasurements, selectedTimeframe])

  const consumption = useMemo(() => {
    if (!consumptionData?.getWindowedMeasurements || consumptionData.getWindowedMeasurements.length < 1)
      return {
        total: 0,
        values: []
      }

    const total = energyTableGetTotal(consumptionData.getWindowedMeasurements)
    const values = energyTableGetValues(consumptionData.getWindowedMeasurements, selectedTimeframe)

    return {
      total: total.round(0, Big.roundHalfUp).toNumber(),
      values
    }
  }, [consumptionData?.getWindowedMeasurements, selectedTimeframe])

  const results = useMemo(() => {
    const result: EnergyTableColumn[] = []
    if (gridDeviceIds && gridImported) {
      result.push({
        title: 'Import',
        imported: {
          type: EnergyTypes.Import,
          values: gridImported.values,
          max: gridImported.total
        }
      })
    }

    if (gridDeviceIds && gridExported) {
      result.push({
        title: 'Export',
        exported: {
          type: EnergyTypes.Export,
          values: gridExported.values,
          max: gridExported.total
        }
      })
    }

    if (solarDeviceIds && solarExported) {
      result.push({
        title: 'Opwek',
        exported: {
          type: EnergyTypes.Solar,
          values: solarExported.values,
          max: solarExported.total
        }
      })
    }

    if (consumptionDeviceIds && consumption) {
      result.push({
        title: 'Verbruik',
        imported: {
          type: EnergyTypes.Usage,
          values: consumption.values,
          max: consumption.total
        }
      })
    }

    return result
  }, [gridImported, gridExported, solarExported, consumption])

  return <EnergyTable results={results} />
}
