import { atom } from 'recoil'

type User = {
  customerId?: string
  userId?: string
}

const defaultUserValues: User = {
  customerId: undefined,
  userId: undefined
}

export const user = atom<User>({
  key: 'user',
  default: defaultUserValues
})
