import useAppContext from '../../hooks/useAppContext'
import { LoadingScreen } from '../../screens/LoadingScreen'

const LoginScreen = () => {
  const { authClient } = useAppContext()

  void authClient.authorize()

  return <LoadingScreen />
}

export default LoginScreen
