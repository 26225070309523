import React, { useEffect } from 'react'
import PowerOverviewGridComponent from '../../dashboard/dashboards/PowerOverviewComponents/PowerOverviewGridComponent'
import PowerOverviewBuildingComponent from '../../dashboard/dashboards/PowerOverviewComponents/PowerOverviewBuildingComponent'
import PowerOverviewSolarComponent from '../../dashboard/dashboards/PowerOverviewComponents/PowerOverviewSolarComponent'
import PowerOverviewStorageComponent from '../../dashboard/dashboards/PowerOverviewComponents/PowerOverviewStorageComponent'
import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'
import { GetGridForPowerOverviewQuery } from '../../dashboard/queries/gridForPowerOverview'
import { useRecoilValue } from 'recoil'
import { building } from '../../store'

const GraphicContainer = styled('div')`
  width: 100%;
  margin: 32px auto 0;
  padding: 0 64px;
  max-width: 1080px;
`

const Row = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const GridSummaryGraphic = () => {
  const { activeBuildingId } = useRecoilValue(building)
  const { data: gridLayoutData, refetch: refetchGridLayout } = useQuery(GetGridForPowerOverviewQuery, {
    variables: {
      buildingId: activeBuildingId
    },
    skip: !Boolean(activeBuildingId)
  })

  useEffect(() => {
    void refetchGridLayout({
      buildingId: activeBuildingId
    })
  }, [activeBuildingId])

  return (
    <GraphicContainer>
      <Row>
        <PowerOverviewGridComponent main={gridLayoutData?.getBuilding?.grid?.main} />
        <PowerOverviewBuildingComponent building={gridLayoutData?.getBuilding?.grid?.consumption} />
        <PowerOverviewSolarComponent solar={gridLayoutData?.getBuilding?.grid?.solar} />
        <PowerOverviewStorageComponent storage={gridLayoutData?.getBuilding?.grid?.storage} />
      </Row>
    </GraphicContainer>
  )
}
