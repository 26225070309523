import { differenceInDays, endOfDay, endOfMonth, endOfWeek, endOfYear, formatISO, isSameDay, isSameMonth, isSameWeek, isSameYear, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns'
import { TimeframeWindow } from '../store'

export enum TimeframeVariant {
  STANDARD,
  CONDENSED
}

interface GetTimeFrame {
  window: string
  start: Date
  end?: Date
  variant?: TimeframeVariant
}

const getWindow = (window: TimeframeWindow | string, variant?: TimeframeVariant) => {
  switch (window) {
    case TimeframeWindow.H24:
    case '24':
      return variant === TimeframeVariant.CONDENSED ? '1h' : '15m'
    case TimeframeWindow.WEEK:
    case 'week':
      return '1d'
    case TimeframeWindow.MONTH:
    case 'maand':
      return '1d'
    case TimeframeWindow.YEAR:
    case 'jaar':
      return '1mo'
    default:
      return '15m'
  }
}

export function getTimeframe({ window, start, end, variant }: GetTimeFrame) {
  switch (window) {
    case '24':
      return {
        start: formatISO(start),
        end: formatISO(endOfDay(start)),
        window: getWindow(window, variant),
        tickValues: 1800000
      }
    case 'week': {
      const startDate = startOfWeek(start, { weekStartsOn: 1 })
      const endWeek = endOfWeek(startDate, { weekStartsOn: 1 })
      const isThisWeek = isSameWeek(startDate, endOfWeek(new Date(), { weekStartsOn: 1 }), { weekStartsOn: 1 })
      const endDate = isThisWeek ? new Date() : endWeek

      return {
        start: formatISO(startDate),
        end: formatISO(endDate),
        window: '1d',
        tickValues: 86400000
      }
    }
    case 'maand': {
      const startDate = startOfMonth(start)
      const endMonth = endOfMonth(startDate)
      const isThisMonth = isSameMonth(startDate, endOfMonth(new Date()))
      const endDate = isThisMonth ? new Date() : endMonth

      return {
        start: formatISO(startDate),
        end: formatISO(endDate),
        window: '1d',
        tickValues: 86400000
      }
    }
    case 'jaar': {
      const startDate = startOfYear(start)
      const endYear = endOfYear(startDate)
      const isThisYear = isSameYear(startDate, endOfYear(new Date()))
      const endDate = isThisYear ? new Date() : endYear

      return {
        start: formatISO(startDate),
        end: formatISO(endDate),
        window: '1mo',
        tickValues: undefined
      }
    }
    case 'custom': {
      const rangeInDays = differenceInDays(end ?? start, start)
      let window = '1d'
      if (rangeInDays < 1) window = '1h'
      if (rangeInDays >= 1 && rangeInDays <= 7) window = '3h'

      return {
        start: formatISO(start),
        end: end ? formatISO(endOfDay(end)) : formatISO(endOfDay(start)),
        window,
        tickValues: 1800000
      }
    }
    default: {
      return {
        start: formatISO(startOfDay(new Date())),
        end: formatISO(endOfDay(new Date())),
        window: '1d',
        tickValues: 60000
      }
    }
  }
}
