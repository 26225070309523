import styled from '@emotion/styled'
import { useMemo } from 'react'
import { useTheme } from '@emotion/react'

export enum BarColors {
  Usage = 'BLUE',
  Import = 'MAGENTA',
  Export = 'YELLOW',
  Solar = 'YELLOW'
}

interface BarProps {
  color: BarColors
  value: number
  max: number
}

interface ValueBarProps {
  width: number
  color: string
}

const Container = styled('div')`
  background-color: ${({ theme }) => theme.colors.barBackground};
  width: 100%;
  height: 16px;
`

const ValueBar = styled('div')<ValueBarProps>`
  height: 16px;
  width: ${({ width }) => `${width}%`};
  background-color: ${({ color }) => color};
`

export const Bar = ({ color, max, value, ...props }: BarProps) => {
  const getBarColor = useGetBarColor()
  const valueWidth = useMemo(() => {
    if (value === 0 && max === 0) return 0
    return Math.round((value / max) * 100)
  }, [max, value])

  return (
    <Container {...props}>
      <ValueBar width={valueWidth} color={getBarColor(color)} />
    </Container>
  )
}

const useGetBarColor = () => {
  const theme = useTheme()

  return (color: BarColors) => {
    switch (color) {
      case BarColors.Usage:
        return theme.colors.barBlue
      case BarColors.Import:
        return theme.colors.barMagenta
      case BarColors.Export:
        return theme.colors.barYellow
      default:
        return theme.colors.barBlue
    }
  }
}
