import styled from '@emotion/styled'
import { EnergyTableColumn } from './EnergyTableColumn'
import React, { useMemo } from 'react'
import { EnergyTableTimestamp } from './EnergyTableTimestamp'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 100, 0.08);
  background-color: #ffffff;
`

const Head = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 72px;
  padding-left: 150px;
  width: 100%;
`

const HeadLeft = styled('div')`
  background: linear-gradient(100deg, rgba(248, 58, 157, 0.1) 1.04%, rgba(255, 255, 255, 0) 59.46%);
  width: 50%;
  height: 100%;
  border-radius: 16px 0 0 0;
`

const HeadRight = styled('div')`
  background: linear-gradient(88deg, rgba(255, 255, 255, 0) 61.18%, rgba(245, 191, 51, 0.2) 100%);
  width: 50%;
  height: 100%;
  border-radius: 0 16px 0 0;
`

const TimestampContainer = styled('div')`
  background-color: rgba(223, 225, 235, 1);
  min-width: 150px;
  max-width: 150px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 126px 27px 33px;
`

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-sizing: content-box;
  overflow: hidden;
`

const ResultsContainer = styled('div')`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  align-self: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 16px 0 8px;
`

interface EnergyTableProps {
  results: EnergyTableColumn[]
}

export const EnergyTable = ({ results }: EnergyTableProps) => {
  const timeStamps = useMemo(() => {
    if (!results || results.length < 1) return []

    const timestamps: string[] = []

    results.forEach(result => {
      const { imported, exported } = result
      if (imported) imported.values?.forEach(value => timestamps.push(value.timestamp))
      if (exported) exported.values?.forEach(value => timestamps.push(value.timestamp))
    })

    return timestamps.filter((value, index, array) => array.indexOf(value) === index)
  }, [results?.[0]])

  return (
    <Container>
      <Head>
        <HeadLeft />
        <HeadRight />
      </Head>
      <Row>
        <TimestampContainer>
          {timeStamps.map(timestamp => (
            <EnergyTableTimestamp timestamp={timestamp} key={`time-${timestamp}`} />
          ))}
        </TimestampContainer>
        <Wrapper>
          <ResultsContainer>
            {results.map(result => (
              <EnergyTableColumn key={`energy-column-${result.title}}`} {...result} />
            ))}
          </ResultsContainer>
        </Wrapper>
      </Row>
    </Container>
  )
}
