import { Suspense } from 'react'
import SideMenu from '../components/SideMenu/SideMenu'
import TopBar from '../components/TopBar/TopBar'
import styled from '@emotion/styled'
import FullScreenLoading from './FullScreenLoading'
import Loader from '../components/base/Loader'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`

//@todo: check if min-height doesn't break other layouts. Needed for warning in empty building
const ContentContainer = styled('div')`
  width: 100%;
  height: fit-content;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  padding: 24px 64px;
  box-sizing: border-box;
`

const Content = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`

const Main = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 100%;
`

interface Props {
  children: React.ReactNode
  title?: string
}

const AppLayout = ({ children, title }: Props) => {
  return (
    <Wrapper>
      <Suspense fallback={<FullScreenLoading />}>
        <Content>
          <Suspense>
            <SideMenu key={'side-menu'} />
          </Suspense>
          <Main>
            <TopBar title={title} />
            <ContentContainer id="content-container">
              <Suspense fallback={<Loader />}>{children}</Suspense>
            </ContentContainer>
          </Main>
        </Content>
      </Suspense>
    </Wrapper>
  )
}

export default AppLayout
