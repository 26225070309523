//@ts-nocheck

import { parseISO } from 'date-fns'
import Big from 'big.js'
import { generateColorRange } from './colorRange'
import theme from '../theme'

type ChartMeasurements = {
  [key: string]: {
    name: string
    type: string
    color?: string
    data: [number, number][]
  }
}

export const transformChartData = ({ data, devices, type }) => {
  const devicesWithData: ChartMeasurements = {}

  if (data.length > 0) {
    //@ts-ignore
    data
      .filter(measurement => Boolean(measurement._value))
      .forEach(measurement => {
        if (!devicesWithData.hasOwnProperty(measurement.deviceid)) {
          devicesWithData[measurement.deviceid] = {
            name: devices.find(device => device.deviceId === measurement.deviceid)?.name ?? '',
            // type,
            data: []
          }
        }

        devicesWithData[measurement.deviceid].data.push([parseISO(measurement._time).getTime(), Big(measurement._value).round(3).toNumber()])
      })

    const series = Object.values(devicesWithData)
    const colors = generateColorRange(theme.colors.blueEnergetic, series.length)
    return series.map((value, index) => {
      value.color = colors[index]
      return value
    })
  }

  return []
}
