import useBuildingContext from '../../building/hooks/useBuildingContext'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GetGridForPowerOverviewQuery } from '../../dashboard/queries/gridForPowerOverview'
import { useEffect, useMemo } from 'react'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../queries/metrics'
import { getTimeframe, TimeframeVariant } from '../../utils/getTimeframe'
import Big from 'big.js'
import { energyTableGetTotal, energyTableGetValues } from '../../dashboard/dashboards/PowerOverviewComponents/PowerOverviewEnergyTable'
import { useRecoilValue } from 'recoil'
import { building, timeframe } from '../../store'

interface EnergyPerCategoryGridTotalsProps {
  gridTotalsOnComplete: (data: any) => void
}

export const EnergyPerCategoryGridTotals = ({ gridTotalsOnComplete }: EnergyPerCategoryGridTotalsProps) => {
  const { activeBuildingId } = useRecoilValue(building)
  const selectedTimeframe = useRecoilValue(timeframe)
  const { start, end, window } = getTimeframe({ ...selectedTimeframe, variant: TimeframeVariant.CONDENSED })

  const { data: gridLayoutData, refetch: refetchGridLayout } = useQuery(GetGridForPowerOverviewQuery, {
    variables: {
      buildingId: activeBuildingId
    },
    skip: !Boolean(activeBuildingId)
  })

  useEffect(() => {
    void refetchGridLayout({
      buildingId: activeBuildingId
    })
  }, [activeBuildingId])

  const solarDevices = useMemo(() => {
    if (gridLayoutData?.getBuilding?.grid?.solar && gridLayoutData?.getBuilding?.grid?.solar.length > 0) {
      //@ts-ignore
      return gridLayoutData.getBuilding.grid.solar.map(entry => entry.deviceId)
    }
    return []
  }, [gridLayoutData])

  const consumptionDevices = useMemo(() => {
    if (gridLayoutData?.getBuilding?.grid?.consumption && gridLayoutData?.getBuilding?.grid?.consumption.length > 0) {
      //@ts-ignore
      return gridLayoutData.getBuilding.grid.consumption.map(entry => entry.deviceId)
    }
    return []
  }, [gridLayoutData])

  const { data: solarExport } = useQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    variables: {
      input: {
        deviceId: solarDevices,
        start,
        end,
        measurement: 'energyExported',
        window
      }
    },
    fetchPolicy: 'no-cache',
    pollInterval: window === '24h' ? 5000 : 0,
    skip: !Boolean(solarDevices && solarDevices.length > 0)
  })

  const { data: consumptionData } = useQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    variables: {
      input: {
        deviceId: consumptionDevices,
        start,
        end,
        measurement: 'energyImported',
        window
      }
    },
    fetchPolicy: 'no-cache',
    pollInterval: window === '24h' ? 5000 : 0,
    skip: !Boolean(consumptionDevices && consumptionDevices.length > 0)
  })

  const solarExported = useMemo(() => {
    if (!solarExport?.getWindowedMeasurements || solarExport.getWindowedMeasurements.length < 1)
      return {
        total: 0,
        values: []
      }

    const total = energyTableGetTotal(solarExport.getWindowedMeasurements)
    const values = energyTableGetValues(solarExport.getWindowedMeasurements, selectedTimeframe)

    return {
      total: total.round(0, Big.roundHalfUp).toNumber(),
      values
    }
  }, [solarExport?.getWindowedMeasurements, selectedTimeframe])

  const consumption = useMemo(() => {
    if (!consumptionData?.getWindowedMeasurements || consumptionData.getWindowedMeasurements.length < 1)
      return {
        total: 0,
        values: []
      }

    const total = energyTableGetTotal(consumptionData.getWindowedMeasurements)
    const values = energyTableGetValues(consumptionData.getWindowedMeasurements, selectedTimeframe)

    return {
      total: total.round(0, Big.roundHalfUp).toNumber(),
      values
    }
  }, [consumptionData?.getWindowedMeasurements, selectedTimeframe])

  useEffect(() => {
    gridTotalsOnComplete({
      solar: solarExported,
      consumption: consumption.values.length > 0 ? consumption : undefined
    })
  }, [solarExported, consumption])

  return null
}
