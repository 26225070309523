import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useAppContext from '../hooks/useAppContext'
import { LoadingScreen } from '../screens/LoadingScreen'

interface RequireAuthProps {
  children: JSX.Element
}

const RequireAuth = ({ children }: RequireAuthProps) => {
  let location = useLocation()
  const { authClient } = useAppContext()
  const [authorized, setAuthorized] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    console.log('Protected route')
    authClient.userManager
      .getUser()
      .then(user => {
        if (user) {
          setAuthorized(true)
        } else {
          setAuthorized(false)
        }
        setLoading(false)
      })
      .catch(() => {
        setAuthorized(false)
        setLoading(false)
      })
  }, [])

  if (loading) return <LoadingScreen />

  if (!authorized) {
    return <Navigate to={'/login'} replace state={{ from: location }} />
  }

  return children
}

export default RequireAuth
