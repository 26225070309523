import { Fragment, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import H4 from '../Typography/H4'
import { useSuspenseQuery } from '@apollo/client'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { GET_BUILDINGS_FOR_USER } from '../../energy/queries/user'
import { useRecoilState } from 'recoil'
import { building } from '../../store'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.blueDark};
  width: 100%;
`

const StyledH4 = styled(H4)`
  color: #ffffff;
`

const BuildingSelectOption = styled.span<{ selected: boolean }>`
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: ${({ selected, theme }) => (selected ? theme.colors.active : '#000000')};
`

interface SelectNewBuildingProps {
  buildingId: string
  buildingName: string
}

const SideMenuBuildingSelector = () => {
  const [activeBuilding, setActiveBuilding] = useRecoilState(building)
  const { data } = useSuspenseQuery(GET_BUILDINGS_FOR_USER)
  const navigate = useNavigate()

  const buildings = useMemo(() => {
    return data?.me?.customer?.buildings ?? []
  }, [data])

  useEffect(() => {
    if (!activeBuilding.activeBuildingId && buildings.length > 0) {
      setActiveBuilding({
        activeBuildingId: buildings[0].buildingId,
        activeBuildingName: buildings[0].name
      })
    }
  }, [buildings])

  const selectNewBuilding = ({ buildingId, buildingName }: SelectNewBuildingProps) => {
    setActiveBuilding({
      activeBuildingId: buildingId,
      activeBuildingName: buildingName
    })
  }

  if (buildings.length === 0) {
    navigate('/account-not-ready')
  }

  return (
    <Container className="h-20 max-h-20 shadow-top-bar px-5 py-7">
      <Listbox value={activeBuilding}>
        {({ open }) => (
          <>
            <div className="relative">
              <div className="inline-flex">
                <div className="inline-flex">
                  <div className="inline-flex text-white">
                    <StyledH4>{activeBuilding?.activeBuildingName}</StyledH4>
                  </div>
                  <Listbox.Button className="inline-flex items-center mx-2 text-sm font-medium text-white">
                    <span className="sr-only">Selecteer een ander gebouw</span>
                    <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  </Listbox.Button>
                </div>
              </div>
              <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options
                  className="
                                    absolute
                                    z-10
                                    mt-1
                                    w-full
                                    overflow-y-auto
                                    bg-white
                                    py-2
                                    px-4
                                    text-base
                                    shadow-lg
                                    focus:outline-none
                                    sm:text-sm">
                  {buildings.map(building => (
                    <>
                      <Listbox.Option key={building.buildingId} value={building.buildingId} className="py-4 cursor-pointer">
                        {({ selected, active }) => (
                          <BuildingSelectOption
                            selected={selected}
                            onClick={() =>
                              selectNewBuilding({
                                buildingId: building.buildingId,
                                buildingName: building.name
                              })
                            }>
                            {building.name}
                          </BuildingSelectOption>
                        )}
                      </Listbox.Option>
                    </>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </Container>
  )
}

export default SideMenuBuildingSelector
