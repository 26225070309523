import { ReactNode, useCallback, useMemo } from 'react'
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { CachePersistor, SessionStorageWrapper } from 'apollo3-cache-persist'
import { setContext } from '@apollo/client/link/context'
import useAppContext from './hooks/useAppContext'
import { onError } from '@apollo/client/link/error'

interface ApolloProps {
  children: ReactNode
}

const cache = new InMemoryCache()

const cachePersistor = new CachePersistor({
  cache,
  storage: new SessionStorageWrapper(window.sessionStorage),
  trigger: 'write'
})

//@todo: URL from env variables. Setup CI/CD in Gitlab
const httpLink = createHttpLink({
  uri: 'https://api.loqio.dev',
  headers: {
    'Loqio-Subscription-Key': '35504a291af7488c89b6a40af4da1602'
  }
})

const Apollo = ({ children }: ApolloProps) => {
  const { authClient } = useAppContext()

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors && graphQLErrors.length > 0) {
      graphQLErrors.forEach(error => {
        if (error.extensions?.code === 'AuthenticationError') {
          authClient?.userManager?.removeUser()
          window.location.reload()
        }
      })
    }
  })

  const authLink = setContext(async (_, { headers }) => {
    const user = await authClient?.userManager?.getUser()
    if (user && user?.access_token) {
      const token = user.access_token
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      }
    } else {
      return headers
    }
  })

  const client = useMemo(() => {
    return new ApolloClient({
      name: 'Dashboard',
      link: authLink.concat(errorLink).concat(httpLink),
      cache
    })
  }, [authClient])

  const clearCache = useCallback(async () => {
    if (!cachePersistor) {
      return
    }
    await cachePersistor.purge()
  }, [cachePersistor])

  //@todo: Implement new to create loading screen, same as in auth
  if (!client) {
    return <h2>Initializing app...</h2>
  }

  client.onClearStore(async () => {
    await clearCache()
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default Apollo
