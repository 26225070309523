import { createContext, ReactNode, useEffect, useState } from 'react'
import { createZitadelAuth, ZitadelAuth, ZitadelConfig } from '@zitadel/react'
import { useLazyQuery } from '@apollo/client'
import { GET_AUTH_ORGANISATION_ID_FOR_DOMAIN } from '../queries/customer'
import { LoadingScreen } from '../screens/LoadingScreen'
import { ErrorOrganisationScreen } from '../screens/errors/ErrorOrganisationScreen'

interface AppProviderProps {
  children: ReactNode
}

type BaseAppContext = {
  authClient: ZitadelAuth
}

type AppContext = BaseAppContext & {}

//@todo: set authority, client_id via env variables via organisation
const defaultZitadelConfig: ZitadelConfig = {
  authority: 'https://idp.loqio.dev',
  client_id: '273348028302163992@loqio_energy_management',
  redirect_uri: `https://dashboard.loqio.app/auth/callback`,
  scope: `openid urn:zitadel:iam:user:metadata`
}

const defaultContext: AppContext = {
  authClient: createZitadelAuth(defaultZitadelConfig)
}

export const AppContext = createContext(defaultContext)

const AppProvider = ({ children }: AppProviderProps) => {
  const [appContext, setAppContext] = useState<AppContext>(defaultContext)
  const [organisationFetchError, setOrganisationFetchError] = useState(false)
  const [zitadelClientInit, setZitadelClientInit] = useState(false)
  const location = window.location
  const [fetchOrganisation] = useLazyQuery(GET_AUTH_ORGANISATION_ID_FOR_DOMAIN, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    setOrganisationFetchError(false)
    fetchOrganisation({
      variables: {
        domain: location.hostname
      }
    })
      .then(response => {
        const { data } = response
        if (!data || !data?.getOrganisationForDomain?.authOrganisationId) {
          setOrganisationFetchError(true)
        } else {
          const orgId = data.getOrganisationForDomain.authOrganisationId
          //@todo: set authority, client_id via env variables

          const redirect = location.port === '3000' ? `${location.protocol}//${location.hostname}:3000/auth/callback` : `${location.protocol}//${location.hostname}/auth/callback`
          const logoutRedirect = location.port === '3000' ? `${location.protocol}//${location.hostname}:3000/auth/logout` : `${location.protocol}//${location.hostname}/auth/logout`

          const zitadelConfig: ZitadelConfig = {
            authority: 'https://idp.loqio.dev',
            client_id: '273348028302163992@loqio_energy_management',
            redirect_uri: redirect,
            scope: `openid urn:zitadel:iam:org:id:${orgId} urn:zitadel:iam:user:metadata`,
            automaticSilentRenew: true,
            post_logout_redirect_uri: logoutRedirect
          }

          setAppContext({
            ...appContext,
            authClient: createZitadelAuth(zitadelConfig)
          })
          setZitadelClientInit(true)
        }
      })
      .catch(() => {
        setOrganisationFetchError(true)
      })
  }, [])

  if (organisationFetchError) return <ErrorOrganisationScreen />
  if (!zitadelClientInit) return <LoadingScreen />
  return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
}

export default AppProvider
