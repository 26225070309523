import { gql } from '../../__generated'

export const GetBuildingGridQuery = gql(/* GraphQL */ `
  query GetBuildingGrid($buildingId: ID!) {
    getBuilding(buildingId: $buildingId) {
      grid {
        distributionBoxes {
          distributionBoxId
          name
          parent {
            distributionBoxId
          }
        }
      }
    }
  }
`)

export const BUILDING_GRID_EXTENDED_QUERY = gql(/* GraphQL */ `
  query GetExtendedBuildingGrid($buildingId: ID!) {
    getBuilding(buildingId: $buildingId) {
      grid {
        main {
          current
          maxPower
          deviceId
          phases
        }
        distributionBoxes {
          description
          distributionBoxId
          name
          circuits {
            circuitId
          }
          parent {
            distributionBoxId
          }
          device {
            deviceId
            name
            data {
              key
              value
            }
            type
            source
          }
        }
      }
    }
  }
`)
