import TimeframeSelectionMenu from '../../components/TimeframeSelectionMenu/TimeframeSelectionMenu'
import { Suspense, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'
import { GetGridForPowerOverviewQuery } from '../queries/gridForPowerOverview'
import { PowerOverviewEnergyTable } from './PowerOverviewComponents/PowerOverviewEnergyTable'
import { GridSummaryGraphic } from '../../energy/components/GridSummaryGraphic'
import { useRecoilValue } from 'recoil'
import { building } from '../../store'
import Centered from '../../components/layout/Centered'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import Loader from '../../components/base/Loader'

const StyledExclamationTriangleIcon = styled(ExclamationTriangleIcon)`
  width: 75px;
  height: 75px;
  color: ${({ theme }) => theme.colors.alert};
`

const Container = styled('div')`
  width: 100%;
  height: 100%;
`

const TimeFrameContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const DetailContainer = styled('div')`
  //width: 100%;
  height: fit-content;
  margin: 50px auto 0;
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 2200px;
`

const PowerOverviewDashboard = () => {
  const { activeBuildingId } = useRecoilValue(building)
  const [gridDeviceIds, setGridDeviceIds] = useState<string[]>()
  const [solarDeviceIds, setSolarDeviceIds] = useState<string[]>()
  const [consumptionDeviceIds, setConsumptionDeviceIds] = useState<string[]>()

  const { data: gridLayoutData, refetch: refetchGridLayout } = useQuery(GetGridForPowerOverviewQuery, {
    variables: {
      buildingId: activeBuildingId
    },
    skip: !Boolean(activeBuildingId)
  })

  useEffect(() => {
    void refetchGridLayout({
      buildingId: activeBuildingId
    })
  }, [activeBuildingId])

  useEffect(() => {
    if (gridLayoutData?.getBuilding?.grid?.main && gridLayoutData?.getBuilding?.grid?.main.length > 0) {
      // @ts-ignore
      const devices = gridLayoutData.getBuilding.grid.main.map(entry => entry.deviceId)
      setGridDeviceIds(devices)
    } else {
      setGridDeviceIds(undefined)
    }

    if (gridLayoutData?.getBuilding?.grid?.solar && gridLayoutData?.getBuilding?.grid?.solar.length > 0) {
      // @ts-ignore
      const devices = gridLayoutData.getBuilding.grid.solar.map(entry => entry.deviceId)
      setSolarDeviceIds(devices)
    } else {
      setSolarDeviceIds(undefined)
    }

    if (gridLayoutData?.getBuilding?.grid?.consumption && gridLayoutData?.getBuilding?.grid?.consumption.length > 0) {
      // @ts-ignore
      const devices = gridLayoutData.getBuilding.grid.consumption.map(entry => entry.deviceId)
      setConsumptionDeviceIds(devices)
    } else {
      setConsumptionDeviceIds(undefined)
    }
  }, [gridLayoutData?.getBuilding?.grid?.main, gridLayoutData?.getBuilding?.grid?.solar, gridLayoutData?.getBuilding?.grid?.consumption])

  return (
    <Container>

      {(!gridDeviceIds || gridDeviceIds.length === 0) && (
        <Centered>
          <StyledExclamationTriangleIcon />
          De indeling van het elektriciteitsnetwerk is niet bekend.
        </Centered>
      )}

      {gridDeviceIds && gridDeviceIds.length > 0 && (
        <>
          <TimeFrameContainer>
            <TimeframeSelectionMenu />
          </TimeFrameContainer>

          <Suspense fallback={<Loader />}>
            <GridSummaryGraphic />
          </Suspense>

          <DetailContainer>
            <Suspense fallback={<Loader />}>
              <PowerOverviewEnergyTable gridDeviceIds={gridDeviceIds} solarDeviceIds={solarDeviceIds} consumptionDeviceIds={consumptionDeviceIds} />
            </Suspense>
          </DetailContainer>
        </>
      )}
    </Container>
  )
}

export default PowerOverviewDashboard
