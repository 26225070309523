import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const StyledMenuItem = styled(Link)<{ active?: boolean }>`
  font-family: 'Red Hat Text', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.menuItem};
  padding: 8px 16px;
  display: block;
  background-color: ${({ active, theme }) => (active ? theme.sideMenu.activeMenuBackgroundColor : 'transparent')};
    width: 100%;
`

interface MenuItemProps {
  title: string
  to: string
  active?: boolean
}

const MenuItem = ({ active, title, to }: MenuItemProps) => {
  return (
    <StyledMenuItem to={to} active={active}>
      {title}
    </StyledMenuItem>
  )
}

export default MenuItem
