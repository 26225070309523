import { gql } from '../../__generated/gql'

export const GetGridForPowerOverviewQuery = gql(/* GraphQL */ `
  query GetGridForPowerOverview($buildingId: ID!) {
    getBuilding(buildingId: $buildingId) {
      grid {
        main {
          deviceId
        }
        solar {
          deviceId
        }
        storage {
          deviceId
        }
        consumption {
          deviceId
        }
      }
    }
  }
`)
