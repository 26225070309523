//@ts-nocheck
import { useApolloClient, useQuery, useReactiveVar } from '@apollo/client'
import { DEVICES_IN_CATEGORY_QUERY } from '../queries/category'
import useBuildingContext from '../../building/hooks/useBuildingContext'
import { useEffect, useMemo } from 'react'
import { energyDeviceTypes } from '../constants/energyDeviceTypes'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../queries/metrics'
import { SelectedTimeframeForReporting } from '../../TimeframeProvider'
import { getTimeframe, TimeframeVariant } from '../../utils/getTimeframe'
import Big from 'big.js'
import { energyTableGetTotal, energyTableGetValues } from '../../dashboard/dashboards/PowerOverviewComponents/PowerOverviewEnergyTable'
import { useRecoilValue } from 'recoil'
import { building, timeframe } from '../../store'

interface EnergyCategoryColumnProps {
  category: string
  onComplete: (data: any) => void
}

export const EnergyCategoryColumn = ({ category, onComplete }: EnergyCategoryColumnProps) => {
  const { activeBuildingId } = useRecoilValue(building)
  const selectedTimeframe = useRecoilValue(timeframe)
  const { start, end, window } = getTimeframe({ ...selectedTimeframe, variant: TimeframeVariant.CONDENSED })

  const { data, loading } = useQuery(DEVICES_IN_CATEGORY_QUERY, {
    variables: {
      buildingId: activeBuildingId!,
      category: category
    },
    skip: !activeBuildingId || !category,
    fetchPolicy: 'no-cache'
  })

  const devices = useMemo(() => {
    if (data?.getDevicesWithCategory && data?.getDevicesWithCategory.length > 0) {
      return data.getDevicesWithCategory.filter(device => energyDeviceTypes.includes(device.type)).map(device => device.deviceId)
    } else {
      return []
    }
  }, [data?.getDevicesWithCategory])

  const { data: energyImported } = useQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    variables: {
      input: {
        deviceId: devices,
        start,
        end,
        measurement: 'energyImported',
        window
      }
    },
    fetchPolicy: 'no-cache',
    pollInterval: window === '24h' ? 5000 : 0,
    skip: !Boolean(devices && devices.length > 0)
  })

  const { data: energyExported } = useQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    variables: {
      input: {
        deviceId: devices,
        start,
        end,
        measurement: 'energyExported',
        window
      }
    },
    fetchPolicy: 'no-cache',
    pollInterval: window === '24h' ? 5000 : 0,
    skip: !Boolean(devices && devices.length > 0)
  })

  const imported = useMemo(() => {
    if (!energyImported?.getWindowedMeasurements || energyImported.getWindowedMeasurements.length < 1)
      return {
        total: 0,
        values: []
      }

    const total = energyTableGetTotal(energyImported.getWindowedMeasurements)
    const values = energyTableGetValues(energyImported.getWindowedMeasurements, selectedTimeframe)

    return {
      total: total.round(0, Big.roundHalfUp).toNumber(),
      values
    }
  }, [energyImported?.getWindowedMeasurements, selectedTimeframe])

  const exported = useMemo(() => {
    if (!energyExported?.getWindowedMeasurements || energyExported.getWindowedMeasurements.length < 1)
      return {
        total: 0,
        values: []
      }

    const total = energyTableGetTotal(energyExported.getWindowedMeasurements)
    const values = energyTableGetValues(energyExported.getWindowedMeasurements, selectedTimeframe)

    return {
      total: total.round(0, Big.roundHalfUp).toNumber(),
      values
    }
  }, [energyExported?.getWindowedMeasurements, selectedTimeframe])

  useEffect(() => {
    onComplete({ category, imported, exported })
  }, [imported, exported])

  return null
}
